import * as React from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import Styles from 'constants/Styles'
import { Translation } from '@planier/localization'
import Hr from 'components/atoms/Hr'

export interface IOwnProps {
    customerNotes: string | null
    employeeNotes: string | null
    generalNotes: string | null
}

const CustomerNotesWrapper = styled.div`
    width: 100%;
    margin-top: ${({ useMargin }: { useMargin: boolean }) => (useMargin ? '15px' : '0')};

    & span {
        display: inline-block;
    }
`

const TextHeader = styled(Typography)`
    color: ${Styles.mainColor.darkGrey};
    font-size: 15px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 10px;
`

/**
 * Presentational component intended for displaying additional information text.
 *
 * If both the employee and the customer has provided notes, both will be diplayed. Otherwise,
 * only the individual notes will be displayed.
 */
const AdditionalNotes: React.FunctionComponent<IOwnProps> = ({ customerNotes, employeeNotes, generalNotes }) => (
    <React.Fragment>
        {employeeNotes && (
            <React.Fragment>
                <TextHeader>{Translation.translateKey('employee-additional-info')}</TextHeader>
                <Typography variant="bodyXS">{employeeNotes}</Typography>
            </React.Fragment>
        )}
        {employeeNotes && customerNotes && <Hr withMargin />}
        {customerNotes && (
            <CustomerNotesWrapper useMargin={!!employeeNotes}>
                <TextHeader>{Translation.translateKey('customer-additional-info')}</TextHeader>

                <Typography variant="bodyXS">{customerNotes}</Typography>
            </CustomerNotesWrapper>
        )}
        {customerNotes && generalNotes && <Hr withMargin />}
        {generalNotes && (
            <CustomerNotesWrapper useMargin={!!customerNotes}>
                <TextHeader>{Translation.translateKey('additional-info')}</TextHeader>

                <Typography variant="bodyXS">{generalNotes}</Typography>
            </CustomerNotesWrapper>
        )}
    </React.Fragment>
)

AdditionalNotes.displayName = 'AdditionalNotes'

export default AdditionalNotes
