import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'

import { TranslatedButton } from '@planier/generic-components'

export interface IColumnManagementModalButtonProps {
    onClick: () => void
}

const StyledButton = styled(TranslatedButton)`
    height: 24px;
    margin-right: 4px;
`

const ButtonContainer = styled.div`
    display: flex;
    /* Don't let IE shrink the button */
    flex-shrink: 0;
    align-items: center;
    position: sticky;
    right: 0;
    /* Adding below css to make white background only in the right side of button */
    background-image: linear-gradient(90deg, transparent, #fff);
    padding-bottom: 5px;
    padding-top: 7px;
`

const ColumnManagementModalButton = ({ onClick }: IColumnManagementModalButtonProps): JSX.Element => (
    <ButtonContainer>
        <StyledButton endIcon="arrow_drop_down" onClick={onClick}>
            configurable-list.ColumnManagementModal.EditColumns
        </StyledButton>
    </ButtonContainer>
)

export default ColumnManagementModalButton
