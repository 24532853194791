/**
 * Inserts given items to array at the index given.
 */
export const insertToArray = <T>(arrayToInsertIn: T[], index: number, ...newItems: T[]): T[] => {
    const isIndexOutOfRange = index < 0 || index > arrayToInsertIn.length
    if (isIndexOutOfRange) {
        throw new RangeError('Index out of bounds!')
    }

    return [...arrayToInsertIn.slice(0, index), ...newItems, ...arrayToInsertIn.slice(index)]
}

export const getValueAsArray = <T>(value: T): T | T[] | null | undefined => {
    if (value === null || value === undefined) {
        return value
    }

    if (value instanceof Set) {
        return [...value]
    }

    if (Array.isArray(value)) {
        return value
    }

    return [value]
}

export const removeDuplicates = (value: any[]): any[] => {
    return [...new Set(value)]
}

/**
 * Removes duplicate objects from an array based on given key.
 * Keeps the last iteration of the object with given key.
 *
 * @param array
 * @param key
 * @returns
 */
export const removeDuplicateObjectsInArrayBasedOnKey = (array: any[], key: string): any[] => {
    return [...new Map(array.map((item) => [item[key], item])).values()]
}

/**
 * Moves an array element from one index to another.
 *
 * @param array array to update
 * @param fromIndex index of the item being moved
 * @param toIndex index to move the item to
 *
 * @returns new array with updated indices
 */
export const changeItemIndex = <T>(array: T[], fromIndex: number, toIndex: number): T[] => {
    const newArray = [...array]

    const startIndex = fromIndex < 0 ? newArray.length + fromIndex : fromIndex

    if (startIndex >= 0 && startIndex < newArray.length) {
        const endIndex = toIndex < 0 ? newArray.length + toIndex : toIndex

        const [item] = newArray.splice(fromIndex, 1)
        newArray.splice(endIndex, 0, item)
    }

    return newArray
}
