import { JSX } from 'react'

type Props = {
    children: JSX.Element | JSX.Element[]
}

const DevOnly = ({ children }: Props): JSX.Element | null => {
    const isDev = process.env.NODE_ENV === 'development'

    if (!isDev) {
        return null
    }

    return <>{children}</>
}

export default DevOnly
