import * as React from 'react'
import { JSX } from 'react'
import styled from '@emotion/styled'
import RowElement from './RowElement'
import IListViewColumnModel from '../../../interfaces/IListViewColumnModel'
import { TDataSourceId } from '@planier/data-source'
import { remToPx } from '@planier/generic-utilities'
import { useSelector } from 'react-redux'
import { selectColumnWidths, selectPinnedColumns } from '../../../State/ConfigurableListSelectors'
import { RootState } from 'typesafe-actions'
import { difference, isEmpty } from 'lodash-es'

export interface IRowElementsProps {
    dataSourceId: TDataSourceId
    item: any
    itemId: string | number
    listId: string
    metadataForColumns: IListViewColumnModel[]
    isLoading: boolean
    offset?: number
}

// prevent offsetting more than the column's width
const calculateOffset = (width: number, offset?: number) => {
    if (!offset) {
        return
    }

    return offset <= width ? offset : width
}

const Container = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 12px;
    flex-grow: 1;
`

const ColumnsContainer = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
`

const PinnedColumnsContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    position: sticky;
    left: 0;
    z-index: 1;
    margin-right: 5px;
    background-color: ${({ theme }) => theme.colors.neutralsWhite100};
    border-right: 1px solid ${({ theme }) => theme.colors.neutralsGrey30};
`

const RowElements = ({
    dataSourceId,
    item,
    itemId,
    listId,
    metadataForColumns,
    isLoading,
    offset,
}: IRowElementsProps): JSX.Element => {
    const columnWidths = useSelector((state: RootState) => selectColumnWidths(state, listId))
    const pinnedColumns = useSelector((state: RootState) => selectPinnedColumns(state, listId))

    let firstVisibleElementId: string | null = null

    const renderColumns = (columns: IListViewColumnModel[]) => {
        return columns.map(({ Id, ViewComponentId, ParameterMapping, StaticParameters, Visible, Width }) => {
            if (!Visible) {
                return null
            }

            if (!firstVisibleElementId) {
                firstVisibleElementId = Id
            }

            const columnWidth = columnWidths?.[Id] || remToPx(Width)

            return (
                <RowElement
                    componentDisabled={isLoading}
                    dataSourceId={dataSourceId}
                    item={item}
                    itemId={itemId}
                    key={Id}
                    propsData={ParameterMapping}
                    staticPropsData={StaticParameters}
                    viewComponentId={ViewComponentId}
                    width={columnWidth}
                    offset={firstVisibleElementId === Id ? calculateOffset(columnWidth, offset) : undefined}
                />
            )
        })
    }

    return (
        <Container>
            {!isEmpty(pinnedColumns) && (
                <PinnedColumnsContainer className="pinned-columns-container">
                    {renderColumns(pinnedColumns)}
                </PinnedColumnsContainer>
            )}

            <ColumnsContainer>{renderColumns(difference(metadataForColumns, pinnedColumns))}</ColumnsContainer>
        </Container>
    )
}

export default RowElements
