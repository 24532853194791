import { Component } from 'react'
import * as React from 'react'
import { connect } from 'react-redux'
import styled from '@emotion/styled'
import Grid from '@material-ui/core/Grid'

import DateUtility from 'common/DateUtility'
import { Translation } from '@planier/localization'
import { ILoadingItemsData } from 'interfaces/actions/ILoadingAction'
import { markAdditionalInformationAcknowledgedAction } from '../action-creators/LisatietokuittausActions'
import Styles from 'constants/Styles'
import AdditionalNotes from './AdditionalNotes'
import { Button, TranslatedButton } from '@planier/generic-components'
import MaterialPopover from 'components/molecules/MaterialPopover'
import { BoundThunk } from '@planier/generic-state'

interface IOwnProps {
    additionalInfoAcknowledged: boolean
    anchorElement: HTMLElement | null
    generalNotes: string | null
    customerNotes: string | null
    dataForLoading: ILoadingItemsData
    datetime: string
    employeeNotes: string | null
    id: string
    markAdditionalInformationAcknowledged: BoundThunk<typeof markAdditionalInformationAcknowledgedAction>
    employeeName: string
    onClose: () => void
    open: boolean
    profession: string | null
    disabled: boolean
}

// @@TODO Use components/atoms/Heading when it supports SC
const Header = styled.h2`
    margin-top: 5px;
    margin-bottom: 10px;
    color: ${Styles.mainColor.darkGrey};
    font-size: ${Styles.font.levelTwoTitle.size};
    font-style: italic;
    font-weight: ${Styles.font.levelTwoTitle.fontWeight};
    font-family: ${Styles.font.levelTwoTitle.family};
    width: 100%;
`

const popoverPaperStyles = {
    maxWidth: '400px',
}

const SubHeader = styled.h5`
    font-family: ${Styles.font.text.family};
    font-size: ${Styles.font.levelThreeTitle.size};
    color: ${Styles.mainColor.darkGrey};
    margin-top: 0px;
    margin-bottom: 25px;
    font-style: italic;
    width: 100%;
`

const GridRight = styled(Grid)`
    margin-top: 35px;
    align-self: flex-end;
`

/**
 * Komponentti työaikatapahtuman lisätietojen näyttämiseen käyttöliittymässä
 */
class AdditionalInfoPopover extends Component<IOwnProps> {
    static readonly displayName = 'AdditionalInfoPopover'

    handleConfirmClick = () => {
        const { additionalInfoAcknowledged, dataForLoading, id, markAdditionalInformationAcknowledged, onClose } =
            this.props

        onClose()
        markAdditionalInformationAcknowledged(!additionalInfoAcknowledged, id, dataForLoading)
    }

    render(): React.ReactNode {
        const {
            additionalInfoAcknowledged,
            anchorElement,
            customerNotes,
            datetime,
            generalNotes,
            employeeNotes,
            employeeName,
            onClose,
            open,
            profession,
            disabled,
        } = this.props

        if (!anchorElement) {
            return null
        }

        return (
            <MaterialPopover anchorElement={anchorElement} onClose={onClose} open={open} style={popoverPaperStyles}>
                <Grid container>
                    <Header>{Translation.translateKey('tapahtuman-lisätiedot')}</Header>
                    {/*  @@TODO Change to use components/atoms/Label or SubHeading once Label uses SC
                        <Label doNotTranslate>Erkki Esimerkki, Siivooja, 12.6.2018</Label> */}
                    <SubHeader>
                        {employeeName}
                        {profession ? `, ${profession}` : ', '}
                        {datetime ? `, ${DateUtility.asFullDate(datetime)}` : ''}
                    </SubHeader>
                    <AdditionalNotes
                        customerNotes={customerNotes}
                        employeeNotes={employeeNotes}
                        generalNotes={generalNotes}
                    />
                    <GridRight container justifyContent="flex-end" wrap="nowrap">
                        {disabled ? (
                            <></>
                        ) : (
                            <Button onClick={this.handleConfirmClick}>
                                {additionalInfoAcknowledged
                                    ? Translation.translateKey('aseta-kuitattavaksi')
                                    : Translation.translateKey('kuittaa-luetuksi')}
                            </Button>
                        )}
                        <TranslatedButton onClick={onClose} variant="text">
                            cancel-button
                        </TranslatedButton>
                    </GridRight>
                </Grid>
            </MaterialPopover>
        )
    }
}

const mapDispatchToProps = {
    markAdditionalInformationAcknowledged: markAdditionalInformationAcknowledgedAction,
}

export default connect(null, mapDispatchToProps)(AdditionalInfoPopover)
