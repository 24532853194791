import { Dispatch } from 'redux'
import { RootState } from 'typesafe-actions'
import { CancelToken } from 'axios'
import makeRequest, { ERequestMethod } from '@planier/rest-api'

import { IThunkBaseAction } from '@planier/generic-state'
import {
    configurableListInitialiseAction,
    configurableListSetConfigurationAction,
    initializeColumnOrderAction,
    initializeColumnWidthsAction,
    initializePinnedColumnsAction,
    listViewReportsFetchedAction,
    resetAllSelectedListItemsAction,
    resetOpenedRowsState,
    setSelectedItemsAction,
    toggleRowItemMassSelectionAction,
    toggleRowItemSelectionAction,
} from '../State/ConfigurableListActions'
import { errorOnPromiseFailed } from 'action-creators/ErrorActions'
import { getListViewMetaData } from '../webApi/ConfigurableListApi'
import {
    dataSourceResetDataAction,
    downloadDataToFileThunk,
    executeDataItemActionThunk,
    executeSingleDataItemActionThunk,
    fetchDataSourceDataExportedToExcelThunk,
    fetchDataSourceDataThunk,
    initializeDataSourceThunk,
    selectDataItemIds,
    selectDataSourceItemsByIds,
    selectLoadingFieldName,
    setSortSettingsAndFetchDataIfAvailableThunk,
} from '@planier/data-source'
import {
    IDataSourceDataRequestGroupBy,
    IDataSourceItem,
    IDataSourceSortParameter,
    TDataSourceItemId,
} from '@planier/data-source-types'
import {
    selectDataSourceId,
    selectIsListAutoFetchDataEnabled,
    selectListConfigurationForColumns,
    selectListViewConfiguration,
    selectListViewFilterIds,
    selectSelectedItemIds,
} from '../State/ConfigurableListSelectors'
import IListActionConfirmationDialogModalProps from '../interfaces/IListActionConfirmationDialogModalProps'
import { CONFIRMATION_DIALOG_ID, openModalAction } from '@planier/modal'
import { selectListAdditionalDataFetchParameters } from './../State/ConfigurableListSelectors'
import changeSortTypeOrGetDefault from '@planier/data-source/Utilities/ChangeSortTypeOrGetDefault'
import { displayErrorToaster } from '@planier/notifications'
import { LIST_MODAL_ID_PREFIX } from '../Constants/ListModalConstants'
import { IInitialValuePickersValues, resetValuePickerValuesAction, TValuePickerId } from '@planier/value-picker'
import IListModalActionValuePickerPropertyMapping from '../interfaces/IListModalActionValuePickerPropertyMapping'
import IConfigurableListModalProps from '../interfaces/IConfigurableListModalProps'
import { asyncOperationStartedAction, asyncOperationSucceededAction } from '@planier/async-operation'
import TListViewId from '../interfaces/TListViewId'
import { ISummaryLabel } from '@planier/generic-components/SummaryLabel/SummaryLabels'
import { get } from 'lodash-es'
import { chain } from 'lodash'

const displayLoadingSpinner = (dispatch: Dispatch, loadingFieldNameForActualData?: string) => {
    dispatch(asyncOperationStartedAction(loadingFieldNameForActualData ?? 'global'))
}

const hideLoadingSpinner = (dispatch: Dispatch, loadingFieldNameForActualData?: string) => {
    dispatch(asyncOperationSucceededAction(loadingFieldNameForActualData ?? 'global'))
}

interface IFetchListDataThunkExtraParams {
    cancelToken?: CancelToken
    resetSelected?: boolean
    resetOffset?: boolean
    showLoadingSpinner?: boolean
}

export type Report = {
    name: string
    includeDataSourcePropertyIds: string[]
    groupByDataSourceProperties: {
        dataSourcePropertyId: string
        transformation: string
        label: string
    }[]
    windowFunctions: {
        dataSourcePropertyId: string
        function: string
    }[]
    id?: number
}

export const fetchListDataThunk =
    (
        listId: string,
        {
            cancelToken,
            resetSelected = false,
            resetOffset = false,
            showLoadingSpinner = true,
        }: IFetchListDataThunkExtraParams = {}
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectDataSourceId(state, listId)
        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        await dispatch(fetchDataSourceDataThunk(dataSourceId, { cancelToken, resetOffset, showLoadingSpinner }))

        dispatch(resetOpenedRowsState(listId))

        if (resetSelected) {
            dispatch(resetAllSelectedListItemsAction(listId))
        } else {
            const selectedItemIds = selectSelectedItemIds(state, listId)

            if (selectedItemIds.size === 0) {
                return
            }

            const allItemIds = selectDataItemIds(state, dataSourceId)
            const allItemIdsAsSet = new Set(allItemIds)

            const selectedItemIdsNoLongerFound = [...selectedItemIds].filter((id) => !allItemIdsAsSet.has(id))

            if (selectedItemIdsNoLongerFound.length === 0) {
                return
            }

            const selectedItemIdsNoLongerFoundAsSet = new Set(selectedItemIdsNoLongerFound)

            const newSelectedItems = [...selectedItemIds].filter((id) => selectedItemIdsNoLongerFoundAsSet.has(id))

            dispatch(setSelectedItemsAction(new Set(newSelectedItems), listId))
        }
    }

export const fetchListViewMetaDataThunk =
    (listId: string): IThunkBaseAction =>
    async (dispatch) => {
        const listViewConfiguration = await getListViewMetaData(listId)

        dispatch(configurableListSetConfigurationAction(listViewConfiguration, listId))
    }

/**
 * Hakee konfiguroitavan listan metadatan, eli itse listakomponenttiin
 * liittyvät tiedot (sarakkeiden nimet, mitä komponentteja käytetään missäkin
 * sarakkeessa jne.)
 */
export const initializeListThunk =
    (
        listId: string,
        additionalDataSourceDataRequestFiltersParameters?: Record<string, unknown> | null,
        initialValuePickersValues?: IInitialValuePickersValues | null,
        resetValuePickerValues?: boolean
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        dispatch(configurableListInitialiseAction(listId))

        let loadingFieldName: string | undefined

        try {
            if (!selectListViewConfiguration(getState(), listId)) {
                await dispatch(fetchListViewMetaDataThunk(listId))
            }

            dispatch(initializeColumnOrderAction(listId))
            dispatch(initializeColumnWidthsAction(listId))
            dispatch(initializePinnedColumnsAction(listId))

            const state = getState()
            const dataSourceId = selectDataSourceId(state, listId)

            if (!dataSourceId) {
                throw new Error(`DataSourceId has not been defined for list ${listId}`)
            }

            const filterIds = selectListViewFilterIds(state, listId)

            if (resetValuePickerValues) {
                const valuePickersWithResettedValues = filterIds.reduce((valuePickerValuesMap, valuePickerId) => {
                    // remove value pickers from state
                    valuePickerValuesMap.set(valuePickerId, { value: undefined })
                    return valuePickerValuesMap
                }, new Map<string, { value: undefined }>())

                await dispatch(resetValuePickerValuesAction(valuePickersWithResettedValues))
            }

            loadingFieldName = selectLoadingFieldName(dataSourceId)
            displayLoadingSpinner(dispatch, loadingFieldName)

            const fetchData = selectIsListAutoFetchDataEnabled(state, listId)

            const listDataFetchParameters = selectListAdditionalDataFetchParameters(state, listId)

            await dispatch(
                initializeDataSourceThunk(dataSourceId, {
                    valuePickerIds: filterIds,
                    fetchData,
                    additionalDataSourceDataRequestFiltersParameters,
                    additionalDataSourceDataRequestParameters: listDataFetchParameters,
                    initialValuePickersValues,
                })
            )
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))
        }

        if (loadingFieldName) {
            hideLoadingSpinner(dispatch, loadingFieldName)
        }
    }

export const executeListItemActionThunk =
    (listId: string, itemIds: TDataSourceItemId[], actionId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectDataSourceId(state, listId)
        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        try {
            const actionRequestParameters = {}
            await dispatch(executeDataItemActionThunk(dataSourceId, itemIds, actionId, actionRequestParameters))

            dispatch(toggleRowItemMassSelectionAction(false, listId, itemIds))
        } catch (error) {
            dispatch(errorOnPromiseFailed(error))
        }
    }

export const executeSingleListItemActionThunk =
    (listId: string, itemId: TDataSourceItemId, actionId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectDataSourceId(state, listId)
        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        const actionRequestParameters = {}

        try {
            await dispatch(executeSingleDataItemActionThunk(dataSourceId, itemId, actionId, actionRequestParameters))

            dispatch(toggleRowItemSelectionAction(false, itemId, listId))
        } catch (error) {
            dispatch(displayErrorToaster(error))
        }
    }

export const executeDownloadActionThunk =
    (listId: string, itemIds: TDataSourceItemId[], actionId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectDataSourceId(state, listId)
        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        dispatch(downloadDataToFileThunk(dataSourceId, itemIds, actionId))
    }

export const openListActionConfirmationDialogThunk =
    (modalProps: IListActionConfirmationDialogModalProps): IThunkBaseAction =>
    async (dispatch) => {
        dispatch(openModalAction(CONFIRMATION_DIALOG_ID, modalProps))
    }

export const upsertListReportThunk =
    (report: Report, listId: TListViewId): IThunkBaseAction =>
    async (dispatch) => {
        await makeRequest<void>({
            method: ERequestMethod.POST,
            url: `ViewEngine/List/${listId}/ListReport`,
            data: report,
        })

        const newListConfig = await getListViewMetaData(listId)

        dispatch(listViewReportsFetchedAction(newListConfig.Reports, listId))
    }

export const deleteReportThunk =
    (reportId: number, listId: string): IThunkBaseAction =>
    async (dispatch) => {
        await makeRequest<void>({
            method: ERequestMethod.DELETE,
            url: `ViewEngine/ListReport/${reportId}`,
        })

        const newListConfig = await getListViewMetaData(listId)

        dispatch(listViewReportsFetchedAction(newListConfig.Reports, listId))
    }

export const setColumnSortedAndFetchSortedDataThunk =
    (
        listId: string,
        sortOrder: IDataSourceSortParameter['Order'] | undefined,
        sortByParameter: IDataSourceSortParameter['Property']
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()

        const dataSourceId = selectDataSourceId(state, listId)
        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        const sortType = changeSortTypeOrGetDefault(sortOrder)
        await dispatch(setSortSettingsAndFetchDataIfAvailableThunk(state, dataSourceId, sortType, sortByParameter))
    }

export const fetchListDataExportedToExcelThunk =
    (
        listId: string,
        groupByParameters?: IDataSourceDataRequestGroupBy[],
        includeProperties?: string[],
        useGroupedData?: boolean
    ): IThunkBaseAction =>
    async (dispatch, getState) => {
        const dataSourceId = selectDataSourceId(getState(), listId)

        if (!dataSourceId) {
            throw new Error(`DataSourceId has not been defined for list ${listId}`)
        }

        const columns = selectListConfigurationForColumns(getState(), listId).filter((x) => x.Visible)
        columns.sort((x) => x.Order)

        const properties = columns.map((x) => x.ParameterMapping.map((pa) => pa.DataSourceProperty)).flat()

        await dispatch(
            fetchDataSourceDataExportedToExcelThunk(
                dataSourceId,
                includeProperties ?? properties,
                groupByParameters,
                useGroupedData
            )
        )
        dispatch(resetAllSelectedListItemsAction(listId))
    }

// This is largely copypaste from getValueForField function of FormViewThunks, but
// slightly changed. See if it could be made into a more general function of data source.
const getValueForValuePicker = (
    items: IDataSourceItem[],
    propertyPath: string,
    isReducedToSingleValue: boolean
): unknown => {
    if (isReducedToSingleValue) {
        return items.map((item) => get(item, propertyPath))
    }

    const distinctValues = chain(items)
        .map((item) => get(item, propertyPath))
        .uniq()
        .value()

    const value = distinctValues.length === 1 ? distinctValues[0] : null

    return value
}

const compileValues = (
    selectedItemIds: TDataSourceItemId[] | null,
    valuePickerValuesPropertyMapping: IListModalActionValuePickerPropertyMapping[] | null,
    dataSourceId: string | null,
    state: RootState
): IInitialValuePickersValues => {
    if (
        !selectedItemIds ||
        !valuePickerValuesPropertyMapping ||
        selectedItemIds.length === 0 ||
        valuePickerValuesPropertyMapping.length === 0 ||
        !dataSourceId
    ) {
        return new Map()
    }

    const items = selectDataSourceItemsByIds(state, dataSourceId, selectedItemIds)

    const initialValueForValuePickers = valuePickerValuesPropertyMapping.reduce(
        (valuePickersValues, { ValuePickerId, PropertyPath, IsReducedToSingleValue, StaticValue }) => {
            const rawValue = StaticValue
                ? StaticValue
                : getValueForValuePicker(items, PropertyPath, IsReducedToSingleValue)

            valuePickersValues.set(ValuePickerId, rawValue)

            return valuePickersValues
        },
        new Map() as Map<TValuePickerId, unknown>
    )

    return initialValueForValuePickers
}

interface IOpenListModalThunkParams
    extends Pick<
        IConfigurableListModalProps,
        | 'additionalDataSourceDataRequestFiltersParameters'
        | 'hiddenValuePickerIds'
        | 'onListRowSelect'
        | 'keepModalOpenOnListRowSelect'
        | 'saveButton'
        | 'shouldEmptyListDataWhenClosingModal'
    > {
    sourceListId?: string | null
    listIdForModal: string
    selectedItemIds?: TDataSourceItemId[] | null
    valuePickerValuesPropertyMapping?: IListModalActionValuePickerPropertyMapping[] | null
    modalTitle: string
    isForm?: boolean
    summaryLabels?: ISummaryLabel[]
}

export const openListModalThunk =
    ({
        sourceListId = null,
        listIdForModal,
        selectedItemIds = null,
        valuePickerValuesPropertyMapping = null,
        modalTitle,
        hiddenValuePickerIds = null,
        additionalDataSourceDataRequestFiltersParameters = null,
        onListRowSelect = null,
        keepModalOpenOnListRowSelect = false,
        saveButton,
        shouldEmptyListDataWhenClosingModal,
        summaryLabels = [],
    }: IOpenListModalThunkParams): IThunkBaseAction =>
    async (dispatch, getState) => {
        const state = getState()
        const sourceListDataSourceId = sourceListId ? selectDataSourceId(state, sourceListId) : null

        const initialValuePickersValues = compileValues(
            selectedItemIds,
            valuePickerValuesPropertyMapping,
            sourceListDataSourceId,
            state
        )

        dispatch(
            openModalAction(LIST_MODAL_ID_PREFIX + listIdForModal, {
                listId: listIdForModal,
                initialValuePickersValues: initialValuePickersValues,
                title: modalTitle,
                hiddenValuePickerIds,
                additionalDataSourceDataRequestFiltersParameters,
                onListRowSelect,
                keepModalOpenOnListRowSelect,
                saveButton,
                shouldEmptyListDataWhenClosingModal,
                summaryLabels,
            })
        )
    }

export const resetListDataThunk =
    (listId: string): IThunkBaseAction =>
    async (dispatch, getState) => {
        const dataSourceId = selectDataSourceId(getState(), listId)

        if (!dataSourceId) {
            return
        }

        dispatch(dataSourceResetDataAction(dataSourceId))
    }
