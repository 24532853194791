import { Fragment } from 'react'
import * as React from 'react'
import Button from '@material-ui/core/Button'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import Icon from '../../Icon'
import Styles from 'constants/Styles'

export interface ITextButtonWithIconProps {
    disabled: boolean
    doNotTranslate: boolean
    icon: string
    label: string
    onClick: (event: React.MouseEvent) => void
}

const Container = styled(Button)`
    /* Do not accept buttons out-of-bounds */
    max-width: 100%;
`

const StyledTypography = styled(Typography)`
    ${({ disabled }: Pick<ITextButtonWithIconProps, 'disabled'>) =>
        disabled &&
        `
        color: ${Styles.supplementaryColor.lightBlue};
    `};
`
/**
 * A button component displaying only text. Optionally displays the material-ui
 * icon specified by name in the icon-prop.
 */
const TextButtonWithIcon: React.FC<ITextButtonWithIconProps> = ({ disabled, icon, label, onClick }) => {
    return (
        <Container disabled={disabled} onClick={onClick}>
            <Fragment>
                <StyledTypography color="primary" disabled={disabled} display="inline" noWrap>
                    {label}
                </StyledTypography>
                {icon && <Icon>{icon}</Icon>}
            </Fragment>
        </Container>
    )
}

export default TextButtonWithIcon
