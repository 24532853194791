import * as React from 'react'

import Menu from './Menu'
import MenuItem from '../Menu/MenuItem'
import Tooltip from '../Tooltip'
import { Translation } from '@planier/localization'
import IMenuItemProps, { TMenuItemValue } from './Types/IMenuItemProps'
import { getLogger } from '@planier/log'

const Log = getLogger('generic-components.MenuWithItems')

type TMenuItemOnClickProp = (value: TMenuItemValue, event: React.SyntheticEvent) => void
type TOnMenuOpen = (event: React.MouseEvent) => void

type TMenuProps = React.ComponentProps<typeof Menu>

interface IMenuWithItemsProps
    extends Pick<TMenuProps, 'anchorPosition' | 'className' | 'onOpen' | 'anchorOrigin' | 'children'> {
    menuItemsData: IMenuItemProps[]

    /**
     * Click handler for the menu items. As an alternative to this, you can
     * pass the `onClick` individually to the menu item objects. If both are given,
     * the `onClick` of the menu items will override this and this function won't
     * be called at all.
     */
    onMenuItemClick?: TMenuItemOnClickProp
    children: (onClick: TOnMenuOpen) => React.ReactNode
    currentlySelectedItem?: TMenuItemValue | null
}

/**
 * Menu component that lists the given items in the menu.
 */
const MenuWithItems: React.FC<IMenuWithItemsProps> = ({
    children,
    menuItemsData,
    onMenuItemClick,
    currentlySelectedItem,
    anchorOrigin,
    className,
    anchorPosition,
    onOpen,
}) => {
    const hasEvenSingleItemWithIcon = menuItemsData.some(({ icon }) => Boolean(icon))

    const renderMenuItems: TMenuProps['renderMenuContent'] = (handleMenuClose) => {
        const handleMenuItemClick = (value: TMenuItemValue | null, event: React.SyntheticEvent) => {
            if (value === null || !onMenuItemClick) {
                Log.error(
                    'no value or onMenuItemClick given to the component and no onClick property given to the menu items'
                )
                return
            }

            onMenuItemClick(value, event)
        }

        return menuItemsData.map(
            (
                {
                    value,
                    label,
                    onClick,
                    isDisabled,
                    tooltip,
                    icon,
                    subMenu,
                    isCheckboxRendered,
                    isSelected,
                    secondaryAction,
                    divider,
                },
                index
            ) => (
                <Tooltip key={label} title={tooltip}>
                    <MenuItem
                        icon={icon}
                        isCheckboxRendered={isCheckboxRendered}
                        isDisabled={
                            isDisabled
                                ? isDisabled
                                : Boolean(currentlySelectedItem && value && currentlySelectedItem === value)
                        }
                        isSelected={isSelected}
                        isSpaceToBeReservedForIcon={hasEvenSingleItemWithIcon}
                        key={`menuItem-${value ?? index}`}
                        label={Translation.has(label) ? Translation.translateKey(label) : label}
                        onClick={(event) => (onClick ? onClick(event) : handleMenuItemClick(value ?? null, event))}
                        onCloseMenu={handleMenuClose}
                        subMenu={subMenu}
                        autoFocus={index === 0}
                        secondaryAction={secondaryAction}
                        divider={divider}
                    />
                </Tooltip>
            )
        )
    }

    return (
        <Menu
            anchorOrigin={anchorOrigin}
            anchorPosition={anchorPosition}
            className={className}
            onOpen={onOpen}
            renderMenuContent={renderMenuItems}
        >
            {(handleMenuOpen) => children(handleMenuOpen)}
        </Menu>
    )
}

export default MenuWithItems
