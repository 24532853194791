import MuiChip, { ChipProps } from '@material-ui/core/Chip'
import { EventHandler, FC, ReactNode } from 'react'
import styled from '@emotion/styled'
import Typography from '@material-ui/core/Typography'

import Icon from './Icon'

interface IChipProps extends ChipProps {
    endIcon?: ChipProps['deleteIcon']
    onEndIconClick?: ChipProps['onDelete']
    /**
     * When given, overrides the endIcon and renders an arrow icon in its place.
     */
    isSelectionIconRendered?: boolean
    /**
     * Displays amount of selections, if the chip is related to some selections (e.g.
     * dropdown values).
     */
    selectionCount?: number
    /**
     * When given, reserves the number given in pixels for the selectionCount even if
     * selectionCount is null or zero.
     */
    spaceReservedForSelectionCount?: number | null
    showDeleteIcon?: boolean
}

const StyledMuiChip = styled(MuiChip, { shouldForwardProp: (propName) => propName !== 'isSelectionIconRendered' })<{
    isSelectionIconRendered: boolean
}>`
    &.MuiButtonBase-root {
        border-radius: 4px;
        outline: ${({ isSelectionIconRendered, theme }) =>
            isSelectionIconRendered ? `1px solid ${theme.colors.primaryTeal60}` : 0};
        padding-right: 4px;
    }

    height: 24px;
    ${({ onClick }) =>
        onClick &&
        `
        cursor: pointer;
    `}

    transition: ${({ theme }) =>
        `background-color ${theme.tokens.transitionQuick}, outline-color ${theme.tokens.transitionQuick}`};
    background-color: ${({ theme, isSelectionIconRendered }) =>
        isSelectionIconRendered ? theme.colors.neutralsWhite100 : theme.colors.primaryTeal30};

    :hover {
        outline-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryHover};
        background-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryHover};
    }

    :focus {
        ${({ onClick, theme }) =>
            onClick &&
            `
            background-color: ${theme.colors.neutralsWhite100};

            &.MuiButtonBase-root {
                outline: 2px solid ${theme.colors.primaryTeal100};
            }
        `}
    }

    :active {
        background-color: ${({ theme }) => theme.colors.primaryCyan11};
        &.MuiButtonBase-root {
            outline: ${({ theme }) => `1px solid ${theme.colors.primaryTeal60}`};
        }
    }

    .MuiChip-deleteIcon {
        display: flex;
        color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryDefault};
        font-size: 19.6px;
    }

    &.MuiButtonBase-root .MuiChip-label {
        padding-right: 8px;
    }
`

export const StringLabel = styled(Typography)`
    font-family: Inter, arial;
    color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryDefault};
`

const NodeSelectionCountContainer = styled.div`
    background-color: ${({ theme }) => theme.componentExtensions.buttons.bgButtonPrimaryDefault};
    height: 18px;
    min-width: 18px;
    color: white;
    text-align: center;
    margin-left: 8px;
    border-radius: 10px;
`

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`

const SelectedCountLabel = styled(Typography)`
    font-size: 12px;
    color: white;
    padding: 0 4px;
`

const SelectedCountEmpty = styled.div`
    width: 17px;
    margin-left: 8px;
`

const SelectionCountNode: FC<{ selectionCount: number | null; spaceReservedForSelectionCount: number | null }> = ({
    selectionCount,
}) => {
    if (selectionCount === null || selectionCount === 0) {
        return <SelectedCountEmpty />
    }

    return (
        <NodeSelectionCountContainer>
            <SelectedCountLabel as={'span'}>{String(selectionCount)}</SelectedCountLabel>
        </NodeSelectionCountContainer>
    )
}

interface ILabelProps {
    selectionCount: number | null
    label: ReactNode
    spaceReservedForSelectionCount: number | null
}

const Label: FC<ILabelProps> = ({ selectionCount, label, spaceReservedForSelectionCount }) => {
    const labelToUse = typeof label === 'string' ? <StringLabel>{label}</StringLabel> : label
    const showSelectionCountNode = Boolean(selectionCount || spaceReservedForSelectionCount)

    return (
        <LabelContainer>
            {labelToUse}
            {showSelectionCountNode && (
                <SelectionCountNode
                    selectionCount={selectionCount}
                    spaceReservedForSelectionCount={spaceReservedForSelectionCount}
                />
            )}
        </LabelContainer>
    )
}

const Chip: FC<IChipProps> = ({
    selectionCount = null,
    onEndIconClick,
    isSelectionIconRendered = false,
    showDeleteIcon = false,
    spaceReservedForSelectionCount = null,
    label,
    onClick,
    ...props
}) => {
    const labelToUse = (
        <Label
            label={label}
            selectionCount={selectionCount}
            spaceReservedForSelectionCount={spaceReservedForSelectionCount}
        />
    )

    const getEndIcon = () => {
        if (isSelectionIconRendered) {
            return <Icon>expand_more</Icon>
        } else if (showDeleteIcon) {
            return (
                <button type="button">
                    <Icon>clear</Icon>
                </button>
            )
        }

        return undefined
    }

    const endIconToUse = getEndIcon()

    const handleDelete: EventHandler<any> = (e) => {
        if (onEndIconClick) {
            onEndIconClick(e)
        } else if (onClick) {
            onClick(e)
        }
    }

    return (
        <StyledMuiChip
            deleteIcon={endIconToUse}
            label={labelToUse}
            onClick={onClick}
            onDelete={endIconToUse && handleDelete}
            isSelectionIconRendered={isSelectionIconRendered}
            {...props}
        />
    )
}

export default Chip
