import * as React from 'react'
import { JSX } from 'react'
import ColumnSettingsRow from './ColumnSettingsRow/ColumnSettingsRow'
import { Container as SortContainer, Draggable } from 'react-smooth-dnd'
import { List } from '@mui/material'
import IListViewUserSettingsForColumns from '../../interfaces/IListViewUserSettingsForColumns'
import { difference, findIndex, isEmpty, keyBy, orderBy } from 'lodash-es'
import { changeItemIndex } from '@planier/generic-utilities'

type Props = {
    values: IListViewUserSettingsForColumns
    setValues: (values: any) => void
}

const ColumnManagementForm = ({ values, setValues }: Props): JSX.Element => {
    const orderedValues = orderBy(values, 'CustomOrder')
    const pinnedColumns = orderedValues.filter(({ Pinned }) => Pinned)

    const handleDrop = ({ removedIndex, addedIndex }: { removedIndex: number; addedIndex: number }) => {
        const actualRemovedIndex = removedIndex + pinnedColumns.length
        const actualAddedIndex = addedIndex + pinnedColumns.length

        const newOrder = changeItemIndex(orderedValues, actualRemovedIndex, actualAddedIndex).map((item, index) => ({
            ...item,
            CustomOrder: index,
        }))

        setValues(keyBy(newOrder, 'ListViewColumnId'))
    }

    const handlePinned = (columnId: string) => {
        const newValues = [...orderedValues]
        const pinnedColumn = newValues.find(({ ListViewColumnId }) => ListViewColumnId === columnId)

        if (!pinnedColumn) {
            return
        }

        pinnedColumn.Pinned = !pinnedColumn.Pinned

        const fromIndex = findIndex(newValues, ({ ListViewColumnId }) => ListViewColumnId === columnId)
        const toIndex = pinnedColumn.Pinned ? pinnedColumns.length ?? 0 : pinnedColumns.length - 1

        const newOrder = changeItemIndex(newValues, fromIndex, toIndex).map((item, index) => ({
            ...item,
            CustomOrder: index,
        }))

        setValues(keyBy(newOrder, 'ListViewColumnId'))
    }

    return (
        <List>
            {!isEmpty(pinnedColumns) && (
                <>
                    {pinnedColumns.map((column) => (
                        <ColumnSettingsRow
                            key={column.ListViewColumnId}
                            columnSettings={column}
                            onPinned={handlePinned}
                            isPinned
                        />
                    ))}
                </>
            )}
            <SortContainer lockAxis="y" dragHandleSelector=".drag-handle" onDrop={handleDrop}>
                {difference(orderedValues, pinnedColumns).map((column) => (
                    <Draggable key={column.ListViewColumnId}>
                        <ColumnSettingsRow columnSettings={column} onPinned={handlePinned} orderable />
                    </Draggable>
                ))}
            </SortContainer>
        </List>
    )
}

export default ColumnManagementForm
