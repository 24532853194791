import deepDiff from 'deep-diff'
import { set } from 'lodash-es'

export const getObjectDiff = <T extends Record<string, unknown>>(
    initialValues: T,
    values: T,
    filteredValues: string[] = []
): Partial<T> => {
    const changes = deepDiff.diff(initialValues, values, (path, key) => filteredValues.includes(key))

    if (!changes) {
        return {}
    }

    return changes.reduce((acc, current: any) => set(acc, current.path, current.rhs), {} as Partial<T>)
}
