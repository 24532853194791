import { createReducer } from 'typesafe-actions'

import { createEmptySetOrWithAllGivenValues } from 'reducers/utilities/ReducerStateUpdateHelp'
import { IConfigurableListState, IConfigurableListStateMap } from '../interfaces/IListState'
import {
    configurableListInitialiseAction,
    configurableListSetConfigurationAction,
    hideColumnAction,
    initializeColumnOrderAction,
    initializeColumnWidthsAction,
    initializePinnedColumnsAction,
    listViewReportsFetchedAction,
    reportSelectedForEditAction,
    resetAllSelectedListItemsAction,
    resetColumnWidthsAction,
    resetOpenedRowsState,
    saveUserSettingsForColumnsAction,
    setColumnWidthAction,
    setDefaultOpenedItem,
    setFiltersHeightAction,
    setMaximumRowActionsAction,
    setPinnedColumnAction,
    setSelectedItemsAction,
    toggleGroupSimilarRowsAction,
    toggleRowItemMassSelectionAction,
    toggleRowItemSelectionAction,
} from './ConfigurableListActions'
import { getLogger } from '@planier/log'
import { TDataSourceItemId } from 'packages/data-source-types'
import { changeItemIndex, getJSONItemFromLocalStorage } from '@planier/generic-utilities'
import { findIndex, orderBy } from 'lodash-es'

const Log = getLogger('configurable-list.ConfigurableListReducer')

/**
 * Exported only for tests
 */
export const INITIAL_STATE: IConfigurableListStateMap = {}

export const LIST_INITIAL_STATE: IConfigurableListState = {
    configuration: null,
    selected: new Set(),
    opened: new Set(),
    defaultOpened: new Set(),
    maximumRowActions: 0,
    filtersHeight: 0,
    columnWidths: {},
}

const logUninitialisedWarning = (action: { type: string }, listId: string) =>
    Log.warn(`Action ${action.type} dispatched to an uninitialised list ${listId}`)

const configurableListReducer = createReducer(INITIAL_STATE)
    .handleAction(configurableListInitialiseAction, (state, action) => {
        const listId = action.payload

        const listState = state[listId] || LIST_INITIAL_STATE

        return { ...state, [listId]: listState }
    })
    .handleAction(configurableListSetConfigurationAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const configuration = action.payload

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(listViewReportsFetchedAction, (state, action) => {
        const reports = action.payload
        const listId = action.meta

        const listState = state[listId]

        if (!listState || !listState.configuration) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newConfig = { ...listState.configuration, Reports: reports }

        const newState = { ...listState, configuration: newConfig }

        return { ...state, [listId]: newState }
    })
    .handleAction(reportSelectedForEditAction, (state, action) => {
        const report = action.payload
        const listId = action.meta

        const listState = state[listId]
        if (!listState || !listState.configuration) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newConfig = { ...listState.configuration, ReportSelectedForEdit: report }

        const newState = { ...listState, configuration: newConfig }

        listState.configuration.ReportSelectedForEdit = report

        return { ...state, [listId]: newState }
    })
    .handleAction(toggleRowItemSelectionAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const { rowItemId, itemSelected } = action.payload
        const newSelectedItems = new Set(listState.selected)

        if (itemSelected) {
            newSelectedItems.add(rowItemId)
        } else {
            newSelectedItems.delete(rowItemId)
        }

        const newListState = {
            ...listState,
            selected: newSelectedItems,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(toggleRowItemMassSelectionAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const selected = createEmptySetOrWithAllGivenValues(action.payload.orderedItemIds, action.payload.selected)

        const newListState = { ...listState, selected }

        return { ...state, [listId]: newListState }
    })
    .handleAction(toggleGroupSimilarRowsAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const toggleRow = (itemId: TDataSourceItemId) => {
            if (opened) {
                newOpenedItems.add(itemId)
            } else {
                newOpenedItems.delete(itemId)
            }
        }

        const { rowItemId, opened, childIds } = action.payload
        const newOpenedItems = new Set(listState.opened)

        toggleRow(rowItemId)

        if (childIds) {
            childIds.forEach((id) => {
                toggleRow(id)
            })
        }

        const newListState = {
            ...listState,
            opened: newOpenedItems,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setSelectedItemsAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newSelected = action.payload

        return {
            ...state,
            [listId]: {
                ...listState,
                selected: newSelected,
            },
        }
    })
    .handleAction(resetAllSelectedListItemsAction, (state, action) => {
        const listId = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const selected = new Set()

        const newListState = { ...listState, selected }

        return { ...state, [listId]: newListState }
    })
    .handleAction(saveUserSettingsForColumnsAction, (state, action) => {
        const listId = action.meta

        const listState = state[listId]
        if (!listState || !listState.configuration) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const { settingsForColumns } = action.payload

        const { Columns } = listState.configuration

        const configuration = {
            ...listState.configuration,
            Columns: Columns.map((column) => {
                const { Visible, ColumnOrder, Width, CustomOrder, Pinned } = settingsForColumns[column.Id]

                return {
                    ...column,
                    Visible,
                    Order: CustomOrder ?? ColumnOrder,
                    Width,
                    Pinned,
                }
            }),
        }

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setMaximumRowActionsAction, (state, action) => {
        const { listId, maximumRowActions } = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newListState = { ...listState, maximumRowActions }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setFiltersHeightAction, (state, action) => {
        const { listId, filtersHeight } = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newListState = { ...listState, filtersHeight }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setDefaultOpenedItem, (state, action) => {
        const { rowItemId, listId } = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newDefaultOpened = new Set(listState.defaultOpened)
        newDefaultOpened.add(rowItemId)

        const newListState = { ...listState, defaultOpened: newDefaultOpened }

        return { ...state, [listId]: newListState }
    })
    .handleAction(resetOpenedRowsState, (state, action) => {
        const listId = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newListState = { ...listState, defaultOpened: new Set([]), opened: new Set([]) }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setColumnWidthAction, (state, action) => {
        const { listId, id, width } = action.payload

        const listState = state[listId]
        if (!listState) {
            logUninitialisedWarning(action, listId)
            return state
        }

        const newColumnWidths = { ...listState.columnWidths }
        newColumnWidths[id] = width

        const newListState = { ...listState, columnWidths: newColumnWidths }

        return { ...state, [listId]: newListState }
    })
    .handleAction(initializeColumnWidthsAction, (state, action) => {
        const { listId } = action.payload

        const savedValues =
            getJSONItemFromLocalStorage<Record<string, Record<string, number>>>('columnWidths')?.[listId]

        const listState = state[listId]

        if (!listState || !savedValues) {
            return state
        }

        return { ...state, [listId]: { ...listState, columnWidths: savedValues } }
    })
    .handleAction(resetColumnWidthsAction, (state, action) => {
        const { listId } = action.payload

        const listState = state[listId]

        if (!listState) {
            return state
        }

        return { ...state, [listId]: { ...listState, columnWidths: {} } }
    })
    .handleAction(initializeColumnOrderAction, (state, action) => {
        const { listId } = action.payload

        const savedValues = getJSONItemFromLocalStorage<Record<string, Record<string, number>>>('columnOrder')?.[listId]

        const listState = state[listId]

        if (!listState || !listState.configuration || !savedValues) {
            return state
        }

        const { Columns } = listState.configuration

        const configuration = {
            ...listState.configuration,
            Columns: Columns.map((column) => {
                return {
                    ...column,
                    Order: savedValues[column.Id] ?? column.Order,
                }
            }),
        }

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(initializePinnedColumnsAction, (state, action) => {
        const { listId } = action.payload

        const savedValues = getJSONItemFromLocalStorage<Record<string, string[]>>('pinnedColumns')?.[listId]

        const listState = state[listId]

        if (!listState || !listState.configuration || !savedValues) {
            return state
        }

        const { Columns } = listState.configuration

        const configuration = {
            ...listState.configuration,
            Columns: Columns.map((column) => {
                return {
                    ...column,
                    Pinned: savedValues.includes(column.Id),
                }
            }),
        }

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(hideColumnAction, (state, action) => {
        const { id, listId } = action.payload

        const listState = state[listId]

        if (!listState || !listState.configuration) {
            return state
        }

        const { Columns } = listState.configuration

        const configuration = {
            ...listState.configuration,
            Columns: Columns.map((column) => {
                return {
                    ...column,
                    Visible: column.Id === id ? false : column.Visible,
                }
            }),
        }

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })
    .handleAction(setPinnedColumnAction, (state, action) => {
        const { id, listId } = action.payload

        const listState = state[listId]

        if (!listState || !listState.configuration) {
            return state
        }

        const { Columns } = listState.configuration

        const orderedColumns = orderBy(Columns, 'Order')
        const pinnedColumns = orderedColumns.filter(({ Pinned }) => Pinned)
        const columnToPin = orderedColumns.find(({ Id }) => Id === id)

        if (!columnToPin) {
            return state
        }

        columnToPin.Pinned = !columnToPin.Pinned

        const fromIndex = findIndex(orderedColumns, ({ Id }) => Id === id)
        const toIndex = columnToPin.Pinned ? pinnedColumns.length ?? 0 : pinnedColumns.length - 1

        const newOrder = changeItemIndex(orderedColumns, fromIndex, toIndex).map((item, index) => ({
            ...item,
            Order: index,
        }))

        const configuration = {
            ...listState.configuration,
            Columns: newOrder,
        }

        const newListState = {
            ...listState,
            configuration,
        }

        return { ...state, [listId]: newListState }
    })

export default configurableListReducer
